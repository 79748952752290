.header {
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.left-buttons {
    margin-left: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.center-logo {
    margin-right: auto; /* Лого будет прижато к левому краю */
}

.right-icons {
    display: flex;
    align-items: center;
}

.button-home {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: larger;
    color: #727272;
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-weight: bold;
}

.button-home::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(53, 47, 47); 
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 0.3s ease-in-out;
}

.button-home:hover {
    color: rgb(53, 47, 47); 
}

.button-home:hover::after {
    transform: scaleY(1);
    transform-origin: top;
}

@media screen and (max-width: 768px) {
    .header {
        flex-direction: column; /* Переключаемся на столбец при уменьшении экрана */
        padding: 10px; /* Уменьшаем отступы */
    }

    .left-buttons {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 10px; /* Добавляем отступ между кнопками */
        margin-left: 0px;
    }

    .center-logo {
        margin-right: 0; /* Убираем отступ для логотипа */
        margin-bottom: 10px; /* Добавляем отступ снизу */
    }

    .right-icons {
        justify-content: center;
        width: 100%;
    }
}


@media screen and (max-width: 1200px) {
    .left-buttons {
      display: none;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-left: 0px;
    }
  
    .left-buttons.open {
      display: flex;
    }
  }
