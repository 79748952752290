.position {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%); /*  Центрирование по вертикали и горизонтали */
}
.root {
    width: 300px;
    padding: 50px;
    border: 1px solid #dedede;
    margin: 150px auto; /* Добавлен отступ вверху */
}

.root-text {
    width: 200px;
    padding: 50px;
    border: 1px solid #dedede;
    margin: 150px auto; /* Добавлен отступ вверху */
    text-align: center !important;
    font-weight: bold !important;
    margin-bottom: 30px !important;
}

.field {
    margin-bottom: 20px !important;
}