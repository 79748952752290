.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    
}
  
.pagination-btn {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.button-pag {
    font-size: 15px;
    width: 110px;
    height: 35px;
    padding: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 15px 5px 15px 5px;
    padding: 5px;
    cursor: pointer;
}

