.custom-button {
    min-width: 300px;
    min-height: 60px;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    /* цвет текста в кнопке */
    color: #313133;
    /* background: #4FD1C5; */
    /* градиент то есть смена цвета слева направо вся кнопка залита цветом со смешиванием*/
    /* background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%); */
    background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgba(79,209,197,1) 100%);
    border: none;
    border-radius: 1000px;
    /* ореол вокруг кнопки */
    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    margin-top: 30px;
}
  
.custom-button::before {
    content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
}
  
.custom-button:hover, .custom-button:focus {
    /* цвет текста в кнопке после наведения на кнопку */
    color: #313133;
    transform: translateY(-6px);
    transform: translate(0%, 0%) scale(1.1);
}
  
.custom-button:hover::before, .custom-button:focus::before {
    opacity: 1;
}
  
.custom-button::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    /* обводка у кнопки расплывающаяся */
    /* border: 6px solid #00FFCB; */
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
}
  
@keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
}


  /* обычная кнопка
  style={{
    borderRadius: '20px', // округлые углы
    padding: '15px 30px', // отступы
    fontSize: '1.2em', // размер шрифта
    backgroundColor: 'purple', // цвет фона
    color: 'white', // цвет текста
    border: 'none', // убираем границу
    cursor: 'pointer', // указатель при наведении
    transition: 'background-color 0.3s ease', // анимация переливания цвета
  }} 
  onMouseOver={(e) => {
        e.target.style.backgroundColor = 'red'; // цвет при наведении
    }}
    onMouseOut={(e) => {
        e.target.style.backgroundColor = 'purple'; // возвращаем исходный цвет
    }}
  */