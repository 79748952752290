/* Header.css */
.header {
    background-color: white;
    /* padding: 15px; */
    height: auto; /* Изменил значение высоты на 'auto' */
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden; /* Добавлено свойство overflow для обрезания содержимого */
}

/* Левая часть 45% */
/* Логотип в центре 10% */
/* Правая часть 45% */
/* Это сместит все элементы вправо */
/* .left-buttons {
    display: flex;
    justify-content: space-between;
    width: 45%; 
}

.center-logo {
    text-align: center;
    width: 10%; 
}

.right-icons {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    width: 45%; 
} */

.left-buttons {
    display: flex;
    justify-content: space-between;
    width: 55%; 
}

.center-logo {
    text-align: flex-start;
    width: 10%; 
}

.right-icons {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    width: 35%; 
} 


.icon {
    margin-left: 10px; /* или другое значение отступа, по вашему усмотрению */
}

.separator {
    width: 100%;
    height: 2px;
    background-color: #ccc;
    /* margin-top: 10px; Расстояние от разделителя до .header */
    margin-bottom: 20px; /* Расстояние между .header и .separator */
}

.button-home {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: larger;
    color: #008a3a;
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-weight: bold;
}

.button-home::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #008a3a;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 0.3s ease-in-out;
}

.button-home:hover {
    color: #005a1e;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.button-home:hover::after {
    transform: scaleY(1);
    transform-origin: top;
}

@media screen and (max-width: 768px) {
    .header {
        flex-direction: column; /* Переключаемся на столбец при уменьшении экрана */
        max-height: none; /* Изменил значение максимальной высоты на 'none' */
    }

    .left-buttons,
    .right-icons,
    .center-logo {
        width: 100%; /* На узком экране, все элементы занимают 100% ширины */
        text-align: center;
    }

    .button-home {
        width: 100%;
        margin-bottom: 10px;
    }
}