.image-poster {
    width: 300px;
}

.title input {
    font-size: 32px;
    font-weight: 900;
}
  
.title div::before,
title div::after {
    display: none;
}
  
.image {
    width: 100%;
}
  
.tags {
    margin: 15px 0;
}
  
.editor {
    margin: 30px -30px;
}
  
.editor .cm-s-easymde {
    border: 0;
    font-size: 22px;
}
  
.editor-toolbar {
    border: 0;
    background-color: rgba(0, 0, 0, 0.02);
}
  
.buttons {
    display: flex;
}
  
.buttons button {
    margin-right: 15px;
}